@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Pretendard Variable', '-apple-system', 'BlinkMacSystemFont',
    'Apple SD Gothic Neo', 'sans-serif' !important;
  -webkit-font-smoothing: 'antialiased';
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  word-break: keep-all;
}

html {
  scrollbar-gutter: auto;
}

/* Scroll Bar */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.scrollbar-hide::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}